import React from "react"
import { chakra, Box, Flex, Text } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Hero,
  VideoPlayer,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { FlyingWVBg } from "../../../components/elements"
import { Helmet } from "react-helmet"

const ChakraImg = chakra(GatsbyImage)
const ChakraBG = chakra(FlyingWVBg)
const datoCmsCancerDonor = ({ data }) => {
  const page = data.datoCmsCancerDonor

  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>{page.fullName} | WVU Cancer Institute</title>
      </Helmet>
      <Hero
        overflow="hidden"
        maxHeight={280}
        textAlign="center"
        bgGradient="linear(to-t, primary, primaryHover)"
        sx={{ color: "primaryHover" }}
      >
        <ChakraBG objectFit="cover" opacity={0.75} />
      </Hero>

      <Box mt={[-24, -24, -28]}>
        <Container>
          <Box width="full" mx="auto">
            <Box mx="auto" display="block" textAlign="center" width="full">
              <ChakraImg
                borderStyle="solid"
                borderWidth={[4, 5, 6]}
                borderRadius="full"
                boxShadow="lg"
                image={page.portrait.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                borderColor="white"
                height={["140px", "180px", "220px"]}
                mx="auto"
                width={["140px", "180px", "220px"]}
              />
            </Box>
          </Box>

          <Box textAlign="center" mt={6}>
            <Box as="h2" mb={2}>
              {page.fullName}
            </Box>
            {page.roleTitle ? <Text mb={0}>{page.roleTitle} </Text> : null}
          </Box>
        </Container>
      </Box>

      <Main>
        {/* Bio */}
        <Section id="bio">
          <Container>
            {/* Bio */}
            <Flex flexWrap="wrap" justifyContent="center">
              {page.video && (
                <Box width={["full", "full", 3 / 4]} mx="auto" mb={12}>
                  <VideoPlayer url={page.video.url} />
                </Box>
              )}

              <Box width={["full", 4 / 5, 2 / 3]}>
                <Box
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default datoCmsCancerDonor

export const query = graphql`
  query donorsPageQuery($slug: String!) {
    datoCmsCancerDonor(slug: { eq: $slug }) {
      id
      callout
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      fullName
      isHighlighted
      meta {
        status
      }
      portrait {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { faceindex: 1, fit: "facearea", facepad: 4 }
        )
      }
      video {
        url
      }
      position
      roleTitle
      slug
    }
  }
`
